exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-trade-information-js": () => import("./../../../src/pages/trade-information.js" /* webpackChunkName: "component---src-pages-trade-information-js" */),
  "component---src-templates-allauthors-js": () => import("./../../../src/templates/allauthors.js" /* webpackChunkName: "component---src-templates-allauthors-js" */),
  "component---src-templates-allbooks-js": () => import("./../../../src/templates/allbooks.js" /* webpackChunkName: "component---src-templates-allbooks-js" */),
  "component---src-templates-allbooksformat-js": () => import("./../../../src/templates/allbooksformat.js" /* webpackChunkName: "component---src-templates-allbooksformat-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-book-js": () => import("./../../../src/templates/book.js" /* webpackChunkName: "component---src-templates-book-js" */),
  "component---src-templates-series-js": () => import("./../../../src/templates/series.js" /* webpackChunkName: "component---src-templates-series-js" */)
}

